@import './components/header.css';
@import './components/login.css';
@import './components/main.css';
@import './components/override.css';
@import './components/responsive.css';

body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

a,
button {
    cursor: pointer;
}

.btn-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-orange {
    background-color: #ef984e;
    color: white;
}
