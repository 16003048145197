.wrapper-components {
    position: relative;
    height: calc(100vh - 70px);
}

.div-controls {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
